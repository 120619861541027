@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f0f4f8;
  color: #333;
  overflow-x: hidden;
}

h1,
h2 {
  font-family: "Merriweather", serif;
  font-weight: 800;
  font-style: normal;
  font-size: 2.5rem;
  /* Large text size */
  line-height: 1.2;
  /* Adjust line height for better spacing */
  margin: 0;
  color: #4a4a4a;
  /* Darker color for titles */
}



.newsletter-label {

  font-size: 16px;
  font-family: "Merriweather", serif;
  font-weight: 400;
  font-style: normal;
  /* color: #6f6262; */
  color: #6f6262;

}

.App {
  text-align: center;
}

.header {
  /* position: fixed; */
  top: 0;
  left: 0;
  /* width: 100%; */
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  /* background-color: #f5f3e3;  */
  /* background-color: transparent; */
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.logo {
  font-size: 22px;
  font-family: "Merriweather", serif;
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  color: #2E0A3C;
  display: flex;
  align-content: center;
  align-items: center;
  gap: 10px;
}

.section {
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;

  transition: background-color 0.5s ease;
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 1s ease, transform 1s ease;
}

.section.visible {
  opacity: 1;
  transform: translateX(0);
}


.section.reverse {
  flex-direction: row-reverse;
  transition: background-color 0.5s ease;
  opacity: 0;
  transform: translateX(20px);
  transition: opacity 1s ease, transform 1s ease;
}

.section.reverse.visible {
  opacity: 1;
  transform: translateX(0);
}

.text-container,
.image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.text-container {
  padding-right: 40px;
}

.section1 .text-container h1 {
  color: #3b3b00;
  /* Darker shade of beige */
  z-index: 1;
}

.section2 .text-container h2 {
  color: #5c3b1e;
  /* Darker shade of brown */
}

.section3 .text-container h2 {
  color: #37454d;
  /* Darker shade of muted blue-gray */
}

.section4 .text-container h2 {
  color: #3b3b00;
  /* Darker shade of beige */
}

.section5 .text-container h2 {
  color: #5c3b1e;
  /* Darker shade of brown */
}



.app-image {
  height: 90%;
  /* Scale image to 80% of the screen height */
  width: auto;
  /* Maintain aspect ratio by adjusting width automatically */
  max-height: 90vh;
  /* Ensure the image does not exceed 80% of viewport height */
  rotate: 2deg;
}


.image-fader {
  height: 90%;
  /* Scale image to 80% of the screen height */
  width: auto;
  /* Maintain aspect ratio by adjusting width automatically */
  /* flex: 1;
  display: flex; */
  /* justify-content: center;
  align-items: center;
  padding: 20px; */
}

.image-fader img {
  position: fixed;
  /* top: 0px;
  left: 0px; */
  animation-name: imagefade;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 6s;
  width: auto;
  /* Scale image to 80% of the width on small screens while keeping proportions */
  height: 90%;
  width: auto;
  /* Maintain aspect ratio by adjusting width automatically */

  z-index: 100001;
}

@keyframes imagefade {
  0% {
    opacity: 1;
  }

  17% {
    opacity: 1;
  }

  30% {
    opacity: 0;
  }

  92% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.image-fader img:nth-of-type(3) {
  animation-delay: 4s;
}

.image-fader img:nth-of-type(2) {
  animation-delay: 2s;
}

.image-fader img:nth-of-type(1) {
  animation-delay: 0;
}


section.reverse .app-image {
  rotate: -2deg;
  /* Rotate image 90 degrees to fit the screen */
}

.section.visible {
  opacity: 1;
  transform: translateX(0);
}

.section.reverse.visible {
  opacity: 1;
  transform: translateX(0);
}

.download-button {
  margin-top: 20px;
  padding: 15px 30px;
  font-size: 18px;
  color: #ffffff;
  background-color: #7a5c3e;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.download-button:hover {
  background-color: #5c3b1e;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-items: space-between;
  padding: 20px;
  background-color: #f0f4f8;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.footer-link {
  margin: 0 10px;
  color: #7a5c3e;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

.section1 {
  background-color: #f5f3e3;
  /* very light beige */
}

.section2 {
  background-color: transparent;
  /* light brown */
}

.section3 {
  background-color: #d1dbdc;
  /* light muted blue-gray */
}

.section4 {
  background-color: #f5f3e3;
  /* very light beige */
}

.section5 {
  background-color: #e9dac2;
  /* light brown */
}

.section6 {
  background-color: #f9f9f9;
  padding: 40px 20px;
}

.text-container {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* Adds space between the bubbles */
}

.chat-bubble-container {
  width: 100%;
  display: flex;
}

.chat-bubble {
  padding: 10px 15px;
  border-radius: 20px;
  font-size: 16px;
  line-height: 1.5;
  display: inline-block;
  /* Makes the bubble fit the text width */
  max-width: 70%;
  /* Prevents bubbles from becoming too wide */
  word-wrap: break-word;
  /* Ensures long words break to a new line */
  font-family: "SF Pro Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
  margin-bottom: 20px;
}

.answer {
  background-color: #e0e0e0;
  /* Grey color */
  color: #000000;
  margin-left: auto;
  /* Aligns to the right */
  border-radius: 20px 20px 0 20px;
  text-align: left;
}

.question {
  color: #ffffff;
  background-color: #218aff;
  /* Grey color */
  margin-right: auto;
  /* Aligns to the left */
  border-radius: 20px 20px 20px 0;
  text-align: left;
}




.newsletter-form {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-left: auto;
}

.newsletter-input {
  padding: 7px;
  font-size: 12px;
  /* Made the text smaller */
  font-weight: bold;
  /* Made the text bold */
  color: rgb(199, 129, 0);
  /* Set the text color to brown */
  background-color: whitesmoke;
  /* Set the background color to whitesmoke */
  border: none;
  /* Removed the border */
  border-radius: 5px;
  /* Retained the border-radius for a subtle rounded effect */
  margin-right: 10px;
  margin-left: 10px;
  width: 220px;
  outline: none;
  /* Removes the default blue border on focus */
}

.newsletter-input:focus {
  outline: none;
  /* Ensure no outline appears when focused */
}


.newsletter-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #ffffff;
  background-color: #7a5c3e;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.newsletter-button:hover {
  background-color: #5c3b1e;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.success-message {
  color: green;
  font-size: 14px;
  margin-top: 5px;
}

/* Responsive Design */
@media screen and (max-width: 800px) {

  .section {
    display: flex;
    flex-direction: column;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease, transform 1s ease;
  }


  .section.reverse {
    display: flex;
    flex-direction: column;
    transform: translateY(20px);
    opacity: 0;
    transition: opacity 1s ease, transform 1s ease;
  }

  .section .visible {
    display: flex;
    flex-direction: column;
    opacity: 1;
    transform: translateY(0);
  }

  .section.reverse.visible {
    display: flex;
    flex-direction: column;
    opacity: 1;
    transform: translateY(0);
  }

  .text-container,
  .image-container {
    width: 80%;
  }

  h1,
  h2 {
    font-family: "Merriweather", serif;
    font-size: 2rem;
    line-height: 1.2;
    margin: 0;
    color: #4a4a4a;
  }

  .app-image {
    height: 70vh;
    width: auto;
    max-height: none;
    /* rotate: 5deg; */
    transform: rotate(5deg);
  }

  .image-fader img {
    position: fixed;
    animation-name: imagefade;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 6s;
    height: 65vh;
    width: auto;
    z-index: 100001;
  }

  section.reverse .app-image {
    height: 70vh;
    width: auto;
    max-height: none;
    /* rotate: -5deg; */
    transform: rotate(-5deg);
  }
}
