.popup-container {
    position: fixed;
    bottom: 20px;
    left: 10%;
    width: 80%;
    display: flex;
    justify-content: center;
    z-index: 1000;
  }
  
  .popup {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    padding: 10px 20px;
    max-width: 400px;
    width: 90%;
    position: relative;
  }
  
  .popup-close {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #f5f5f5;
    border-radius: 50%;
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #666;
  }
  
  .popup-favicon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 10px;
  }
  
  .popup-text {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    text-align: start;
  }
  
  .popup-title {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .popup-description {
    font-size: 12px;
    color: #666;
  }
  
  .popup-appstore {
    width: 120px;
    margin-left: 10px;
  }
  